import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/defense/",
    label: "DEFENSE",
  },
]

const resourcesList = [
  {
    title: "Penetration Testing",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR",
    subtitle: "Managed Vulnerability Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMDR ",
    subtitle: "Managed Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="DEFENSE" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>DEFENSE</h1>
              <h4>SECURITY AND COMPLIANCE</h4>
              <p className="content">
                Ensuring Attestation with CMMC Standards
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-defense">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                INCREASE <br /> SECURITY
              </h3>
              <h6>
                MAINTAIN COMPLIANCE. <br />
                OMNIDIRECTIONAL VISIBILITY.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              In addition to integrated 24/7/365 service and monitoring,
              vulnerability/attack surface reduction and maximum visibility over
              a company’s entire cyber landscape, Vigilant also supports
              defense-industry specific compliance attestation.
            </p>
            <p>
              Too many companies lack the expertise and people to keep up with
              ever-accelerating security compliance requirements. From
              Cybersecurity Maturity Model Certificating (CMMC), to National
              Institute of Standards and Technology (NIST) or others,
              contractors need support to understand the exact level of security
              requirements and the implementation of the hundreds of checklist
              items their specific compliance demands.
            </p>
            <p>
              Our approach is surgically designed: between MDR, Unlimited Breach
              Response services, Penetration Testing, MVDR services, adversary
              emulation, etc. We already cover most of that compliance
              framework.
            </p>

            <ol>
              <li>Determine your compliance needs.</li>
              <li>
                We identify each requirement of your specific compliance
                framework (there can be hundreds).{" "}
              </li>
              <li>
                Map out and deploy the Vigilant services that address your
                compliance categories so you can keep working.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “We did the math and Vigilant meets over 80% of the NIST (Now
                  CMMC) requirements. This was very helpful to us.”
                </h3>
                <hr />
                <h6>- CLIENT IN DEFENSE INDUSTRY</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
